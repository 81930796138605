:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--mobileMenuImg: url(RESOURCE/img/gruppenchalets-header-landschaft.Xc_900x600.jpg);
	--navbarbreak: 1280px;
	--primary: #e5c80c;
	--secondary: #6786a1;
	--light-bg: #f8f8f8;
	--text: #999999;
	--heading: 'Barlow', sans-serif;
	--body: 'Open Sans', sans-serif;

	--black: #000000;
	--d-font: 'DINRoundPro', sans-serif;
	--d-font2: 'DINRoundPro-Medi', sans-serif;
	--white: #fff;
	--d-blue: #033848;
	--d-blue2: #03364d;
	--h1-font: 'Julius Sans One', sans-serif;
	--grey: #e5e5e5;
	--d-grey: #727272;
	--blue3: #025a84;
	--f-nunito: 'Nunito', sans-serif;
	--blue4: #052444;
	--li-blue: #daf0fb;
	--grey2: #7b7b7b;
	--red: #f55454;
	--grey3: #999999;
	--d-font3: 'Ubuntu', sans-serif;
	--grey4: #f9f9f9;

	/*--- calendar colors ---*/
	--unit-cal-not-available-bg: #F75B4F;
	--unit-cal-not-available-color: #000;

	--unit-cal-disabled-bg: #fff;
	--unit-cal-disabled-color: var(--color-grey-dark);

	--unit-click-not-possible-bg: #E2F1FB;
	--unit-click-not-possible-color: var(--color-grey-dark);

	--unit-click-possible-bg: #C0E3FB;
	--unit-click-possible-color: var(--color-black);

	--unit-cal-selected: #0770BA;
	--unit-cal-selection-range: #4FA2DC;
	--unit-cal-selected-border: #fff;
	--unit-cal-text-color: #333;
	--unit-cal-text-color-hover: white;
	--unit-cal-text-color-selected: white;

	/* --- datepicker  colors */
	--cal-not-available: #cd7898;
	--cal-disabled: rgba(38, 135, 81, .7);
	--cal-available: #258750;
	--cal-selected: #227647;
	--cal-selection-range: #6786a1;
	--cal-selected-border: #fff;
	--cal-text-color: #000;
	--cal-text-color-hover: #000;
	--cal-text-color-selected: #000;

	--unit-cal-not-available-bg: #cd7898;
	--unit-cal-not-available-color: #000;
	--unit-cal-disabled-bg: #fff;
	--unit-cal-disabled-color: #000;

	--unit-click-not-possible-bg: #b3d5c2;
	--unit-click-not-possible-color: #5e5d5d;

	--unit-click-possible-bg: #258750;
	--unit-click-possible-color: var(--color-black);

	--unit-cal-selected: #227647;
	--unit-cal-selection-range: #6786a1;
	--unit-cal-selected-border: #fff;
	--unit-cal-text-color: #000;
	--unit-cal-text-color-hover: #000;
	--unit-cal-text-color-selected: #000;

	/*Calendar colors*/
	--not_available: #cd7898;
	--disabled: rgba(38, 135, 81, .7);
	/*--available: #fff;*/
	--available: #258750;
	--selected: #227647;
	/*--selected: #062e5f;*/
	/*--selection_range: #4D6E96;*/
	--selection_range: #6786a1;
	--selected_border: #fff;
	--text_color: #000;
}